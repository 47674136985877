import "normalize.css";

import * as React from "react";

import Footer from "../Footer/Footer";
import GlobalStyles from "../../styles/GlobalStyles.js";
import Navbar from "../Nav/Navbar";
import Typography from "../../styles/Typography.js";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(200px, 1200px) auto;
`;

export default function Layout({ children }: any) {
  return (
    <div>
      <GlobalStyles />
      <Typography />
      <main>
        <Container>
          <aside style={{ background: "#154854" }}></aside>
          <Navbar />
          <aside style={{ background: "#154854" }}></aside>
        </Container>
        {children}
        <Footer />
      </main>
    </div>
  );
}
