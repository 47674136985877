import * as React from 'react';
import styled from 'styled-components';
import { IoLogoFacebook } from 'react-icons/io';
import { IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';

const StyledFooter = styled.footer`
  background-color: #000;
  color: #fff;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  button {
    width: auto;
    height: auto;
    margin-right: 1rem;
    margin-left: 1rem;
    background: transparent;
  }
  button:hover {
    background: white;
    color: black;
  }
  button:active {
    background: grey;
  }
`;

const Button = styled.button`
  &:hover {
    background-color: #361b0d;
    color: #ffb400;
  }
  border-width: 8px;
  background-color: #cbab5e;
  color: #502c1a;
  border-radius: 0px;
  letter-spacing: 2px;
  font-size: 14px;
  /* font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif; */
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
`;

const onInstaClick = () => {
  return;
};
const onFcbClickHandler = () => {
  return;
};

export default function Footer() {
  return (
    <StyledFooter>
      <p>
        <a href="https://www.instagram.com/alex_decor_london/" target="_blank">
          <Button
            type="button"
            aria-label="visit my instagram page"
            onClick={onInstaClick}
          >
            <IoLogoInstagram style={{ width: '4em', height: '4em' }} />
          </Button>
        </a>
        <a
          href="https://www.facebook.com/Alex-Decor-107681070809018"
          target="_blank"
        >
          <Button
            type="button"
            aria-label="visit my facebook page"
            onClick={onFcbClickHandler}
          >
            <IoLogoFacebook style={{ width: '4em', height: '4em' }} />
          </Button>
        </a>
        <a
          href="https://www.linkedin.com/in/aleksandras-ignatovicius-03049a1a1/"
          target="_blank"
        >
          <Button
            type="button"
            aria-label="visit my facebook page"
            onClick={onFcbClickHandler}
          >
            <IoLogoLinkedin style={{ width: '4em', height: '4em' }} />
          </Button>
        </a>
      </p>
      &copy; {new Date().getFullYear()}
    </StyledFooter>
  );
}
