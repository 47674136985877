import "./navbar.css";

import * as React from "react";
import * as S from "./styles";

import { Link } from "gatsby";
import Logo from "../../images/logo.png";

export default function Navbar() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <S.Nav>
        <Link to="/">
          <S.Logo src={Logo} alt="Alex Decor" />
        </Link>
        <S.StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </S.StyledBurger>
        <S.Ul open={open}>
          <li>
            <Link to="/" onClick={() => setOpen(!open)}>
              Home
            </Link>
          </li>

          <li>
            <Link to="/aboutUs" onClick={() => setOpen(!open)}>
              About us
            </Link>
          </li>

          <li>
            <Link to="/projects" onClick={() => setOpen(!open)}>
              Projects
            </Link>
          </li>

          <li className="desktop-finishes-menu">
            <div className="dropdown">
              <button className="dropbtn">Finishes</button>
              <div className="dropdown-content">
                <Link to="/finishes/marmorino" onClick={() => setOpen(!open)}>
                  Marmorino
                </Link>
                <Link to="/finishes/microcement" onClick={() => setOpen(!open)}>
                  Micro-Cement
                </Link>
                <Link to="/finishes/travertino" onClick={() => setOpen(!open)}>
                  Travertino
                </Link>
                <Link to="/finishes/rust-effect" onClick={() => setOpen(!open)}>
                  Rust Effect
                </Link>
                <Link to="/finishes/art-beton" onClick={() => setOpen(!open)}>
                  Art Beton
                </Link>
                <Link to="/finishes/lime-paint" onClick={() => setOpen(!open)}>
                  Lime Paint
                </Link>
              </div>
            </div>
          </li>

          <li className="mobile-finishes-menu">
            <span className="title">Finishes</span>
            <div>
              <li>
                <Link to="/finishes/marmorino" onClick={() => setOpen(!open)}>
                  Marmorino
                </Link>
              </li>
              <li>
                <Link
                  to="/finishes/microcementf"
                  onClick={() => setOpen(!open)}
                >
                  Micro-Cement
                </Link>
              </li>
              <li>
                <Link to="/finishes/travertino" onClick={() => setOpen(!open)}>
                  Travertino
                </Link>
              </li>
              <li>
                <Link to="/finishes/rust-effect" onClick={() => setOpen(!open)}>
                  Rust Effect
                </Link>
              </li>
              <li>
                <Link to="/finishes/art-beton" onClick={() => setOpen(!open)}>
                  Art Beton
                </Link>
              </li>
              <li>
                <Link to="/finishes/lime-paint" onClick={() => setOpen(!open)}>
                  Lime Paint
                </Link>
              </li>
            </div>
          </li>
          <li>
            <Link to="/contactUs" onClick={() => setOpen(!open)}>
              <S.StyledButton>Contact Us</S.StyledButton>
            </Link>
          </li>
        </S.Ul>
      </S.Nav>
    </>
  );
}
