import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --dark-brown: rgb(40, 38, 36)
  }
  html {
    font-size: 10px;
  }

  body {
    font-size: 2rem;
  }

  main {
    background-color: #282625;
    color: #fff;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--black);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    width: 20rem;
    height: 4.5rem;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    /* width: 12px; */
  }
  html {
    /* scrollbar-width: thin; */
    /* scrollbar-color: var(--dark-brown) var(--white); */
  }
  body::-webkit-scrollbar-track {
    /* background: var(--black); */
  }
  body::-webkit-scrollbar-thumb {
    /* background-color: */
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;
