exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contactUs.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-finishes-art-beton-tsx": () => import("./../../../src/pages/finishes/art-beton.tsx" /* webpackChunkName: "component---src-pages-finishes-art-beton-tsx" */),
  "component---src-pages-finishes-lime-paint-tsx": () => import("./../../../src/pages/finishes/lime-paint.tsx" /* webpackChunkName: "component---src-pages-finishes-lime-paint-tsx" */),
  "component---src-pages-finishes-marmorino-tsx": () => import("./../../../src/pages/finishes/marmorino.tsx" /* webpackChunkName: "component---src-pages-finishes-marmorino-tsx" */),
  "component---src-pages-finishes-microcement-tsx": () => import("./../../../src/pages/finishes/microcement.tsx" /* webpackChunkName: "component---src-pages-finishes-microcement-tsx" */),
  "component---src-pages-finishes-rust-effect-tsx": () => import("./../../../src/pages/finishes/rust-effect.tsx" /* webpackChunkName: "component---src-pages-finishes-rust-effect-tsx" */),
  "component---src-pages-finishes-travertino-tsx": () => import("./../../../src/pages/finishes/travertino.tsx" /* webpackChunkName: "component---src-pages-finishes-travertino-tsx" */),
  "component---src-pages-finishes-tsx": () => import("./../../../src/pages/finishes.tsx" /* webpackChunkName: "component---src-pages-finishes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

